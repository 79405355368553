<template>
  <div>
    <header-banner
      banner-img="https://static.moegoat.com/images/banner/banner-4.png"
      title="会员升级方案"
      sub-title="在这里，获得超过500,000+资源下载"
      class="SecondaryNav"
    />
    <v-tabs class="mt-4" v-model="tab" centered>
      <v-tab>VIP</v-tab>
      <v-tab>巴士会 SVIP🔥</v-tab>
    </v-tabs>

    <v-container
        class="mb-13"
      :class="{
        'mt-0 px-5': $vuetify.breakpoint.xsOnly,
        'mt-5': $vuetify.breakpoint.smAndUp,
      }"
    >
      <v-tabs-items v-model="tab">
        <v-tab-item><v-row class="premium-plan">
          <v-col cols="12" md="3">
            <v-hover v-slot:default="{ hover }">
              <v-card
                  class="vip-card py-10 d-flex flex-column align-center"
                  :elevation="hover ? 12 : 5"
              >
                <v-img
                    lazy-src="https://static.moegoat.com/images/vip/v1.png"
                    src="https://static.moegoat.com/images/vip/v1.png"
                    height="150px"
                    contain
                >
                  <template v-slot:placeholder>
                    <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                      <v-progress-circular
                          indeterminate
                          color="grey lighten-1"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-card-title class="my-3 headline">⭐️ 月度VIP</v-card-title>
                <span class="display-1 mb-2">¥35</span>
                <span class="title mt-4"
                >🛺 初体验
                <v-chip style="position: relative; bottom: 2px"
                >15次解锁/天</v-chip
                ></span
                >
                <span class="title mt-4">🚃 VIP服务器</span>
                <span class="title mt-4">⭐️ 解锁海量VIP资源</span>
                <v-btn
                    width="150px"
                    height="42px"
                    dark
                    class="mt-10 elevation-5 regBtn"
                    to="/order?plan=0"
                >升级</v-btn
                >
              </v-card>
            </v-hover>
          </v-col>
          <v-col cols="12" md="3">
            <v-hover v-slot:default="{ hover }">
              <v-card
                  class="vip-card py-10 d-flex flex-column align-center"
                  :elevation="hover ? 10 : 2"
              >
                <v-img
                    lazy-src="https://static.moegoat.com/images/vip/v2.png"
                    src="https://static.moegoat.com/images/vip/v2.png"
                    height="150px"
                    contain
                >
                  <template v-slot:placeholder>
                    <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                      <v-progress-circular
                          indeterminate
                          color="grey lighten-1"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-card-title class="my-3 headline">🌟 季度水晶VIP</v-card-title>
                <span class="display-1 mb-2">¥66</span>
                <span class="title mt-4"
                >🚌 进阶司机
                <v-chip style="position: relative; bottom: 2px"
                >25次解锁/天</v-chip
                ></span
                >
                <span class="title mt-4">🚁 VIP服务器</span>
                <span class="title mt-4">🌟 解锁高级VIP资源</span>
                <v-btn
                    width="150px"
                    height="42px"
                    dark
                    class="mt-10 elevation-5 regBtn"
                    to="/order?plan=1"
                >升级</v-btn
                >
              </v-card>
            </v-hover>
          </v-col>
          <v-col cols="12" md="3">
            <v-hover v-slot:default="{ hover }">
              <v-card
                  class="
                vip-card
                py-10
                d-flex
                flex-column
                align-center
                premium-plan--highlighted
              "
                  :elevation="hover ? 10 : 2"
              >
                <v-chip
                    label
                    style="
                  position: absolute;
                  top: 20px;
                  left: 20px;
                  color: #3930d8;
                "
                    color="#fff"
                    class="font-weight-bold"
                >🔥 推荐</v-chip
                >
                <v-img
                    lazy-src="https://static.moegoat.com/images/vip/v3.png"
                    src="https://static.moegoat.com/images/vip/v3.png"
                    height="150px"
                    contain
                >
                  <template v-slot:placeholder>
                    <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                      <v-progress-circular
                          indeterminate
                          color="grey lighten-1"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-card-title class="my-3 headline">💎 年度钻石VIP</v-card-title>
                <span class="display-1 mb-2 font-weight-light">
                <del style="font-style: italic">¥200</del>
                <span style="color: #fff50e" class="font-weight-bold ml-3 mr-1"
                >¥98</span
                >
                <v-chip
                    color="#FFF50E"
                    class="font-weight-regular"
                    style="position: relative; top: -3px"
                >⏰ 限时</v-chip
                ></span
                >
                <span class="title mt-4"
                >🏎️ 专业车手
                <v-chip
                    dark
                    color="blue"
                    style="position: relative; bottom: 3px"
                >40次解锁/天</v-chip
                ></span
                >
                <span class="title mt-4 font-weight-light"
                >🚀 SVIP高速服务器<span class="font-weight-bold"
                >+⚡优化线路</span
                ></span
                >
                <span class="title mt-4 font-weight-bold"
                >💎 解锁甄选稀缺资源</span
                >

                <v-btn
                    width="150px"
                    height="42px"
                    class="mt-10 elevation-5 goPremium-btn"
                    to="/order?plan=2"
                >限时升级</v-btn
                >
              </v-card>
            </v-hover>
          </v-col>
          <v-col cols="12" md="3">
            <v-hover v-slot:default="{ hover }">
              <v-card
                  class="
                vip-card
                py-10
                d-flex
                flex-column
                align-center
                premium-plan--lifetime
              "
                  :elevation="hover ? 10 : 2"
              >
                <v-img
                    lazy-src="https://static.moegoat.com/images/vip/v4.png"
                    src="https://static.moegoat.com/images/vip/v4.png"
                    height="150px"
                    contain
                >
                  <template v-slot:placeholder>
                    <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                      <v-progress-circular
                          indeterminate
                          color="grey lighten-1"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-card-title class="my-3 headline">👑 终身国王VIP</v-card-title>
                <span class="display-1 mb-2 font-weight-light">
                <del style="font-style: italic">¥600</del>
                <span style="color: #fff50e" class="font-weight-bold ml-3 mr-1"
                >¥198</span
                >
                <v-chip
                    color="#FFF50E"
                    class="font-weight-regular"
                    style="position: relative; top: -3px"
                >💎 限量发行</v-chip
                ></span
                >
                <span class="title mt-4"
                >🛸 史诗级
                <v-chip
                    dark
                    color="#ffad03"
                    class="font-weight-bold"
                    style="position: relative; bottom: 3px"
                >60次解锁/天</v-chip
                ></span
                >
                <span class="title mt-4 font-weight-light"
                >🚀 SVIP极速服务器<span class="font-weight-bold"
                >+⚡高级优化线路</span
                ></span
                >
                <span class="title mt-4 font-weight-bold"
                >👑 解锁独家顶级资源</span
                >
                <v-btn
                    width="150px"
                    height="42px"
                    class="mt-10 elevation-5 goPremium-btn"
                    to="/order?plan=3"
                    color="#fff"
                >限量升级</v-btn
                >
              </v-card>
            </v-hover>
          </v-col>
        </v-row></v-tab-item>
        <v-tab-item>
          <v-alert
              color="primary"
              dark
              icon="mdi-bus-articulated-front"
              border="left"
              prominent
          >
            什么是巴士会SVIP？
            <div>巴士会是进阶的特别身份，除了包含全部VIP的权益以外，增加了在线观看、双倍解锁次数等额外权益。</div>
          </v-alert>
          <v-alert color="#FF70AB" dark border="left" v-if="vipLevel === 4 && svip !== 1">
            <v-row align="center">
              <v-col class="grow">
                ‍👑 您是尊贵的终身VIP，支付差价即可加入巴士会畅享最佳权益（限时30天）
              </v-col>
              <v-col class="shrink">
                <v-btn color="#FF5580" to="/order?plan=3&svip=1">🚍升级</v-btn>
              </v-col>
            </v-row>
          </v-alert>
          <v-row class="premium-plan">
          <v-col cols="12" md="3">
            <v-hover v-slot:default="{ hover }">
              <v-card
                  class="vip-card py-10 d-flex flex-column align-center"
                  :elevation="hover ? 12 : 5"
              >
                <v-img
                    lazy-src="https://static.moegoat.com/images/vip/v1.png"
                    src="https://static.moegoat.com/images/vip/v1.png"
                    height="150px"
                    contain
                >
                  <template v-slot:placeholder>
                    <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                      <v-progress-circular
                          indeterminate
                          color="grey lighten-1"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-card-title class="my-3 headline">⭐️ 1个月巴士会SVIP</v-card-title>
                <span class="display-1 mb-2">¥50</span>
                <span class="title mt-4"
                >🛺 初体验PLUS
                <v-chip style="position: relative; bottom: 2px"
                >30次解锁/天</v-chip
                ></span
                >
                <span class="title mt-4">▶️ 视频在线播放</span>
                <span class="title mt-4">🖼️ 图包在线浏览</span>
                <span class="title mt-4">🚃 SVIP服务器</span>
                <span class="title mt-4">⭐️ 解锁海量SVIP资源</span>
                <v-btn
                    width="150px"
                    height="42px"
                    dark
                    class="mt-10 elevation-5 regBtn"
                    to="/order?plan=0&svip=1"
                >升级</v-btn
                >
              </v-card>
            </v-hover>
          </v-col>
          <v-col cols="12" md="3">
            <v-hover v-slot:default="{ hover }">
              <v-card
                  class="vip-card py-10 d-flex flex-column align-center"
                  :elevation="hover ? 10 : 2"
              >
                <v-img
                    lazy-src="https://static.moegoat.com/images/vip/v2.png"
                    src="https://static.moegoat.com/images/vip/v2.png"
                    height="150px"
                    contain
                >
                  <template v-slot:placeholder>
                    <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                      <v-progress-circular
                          indeterminate
                          color="grey lighten-1"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-card-title class="my-3 headline">🌟 3个月巴士会SVIP</v-card-title>
                <span class="display-1 mb-2">¥108</span>
                <span class="title mt-4"
                >🚌 进阶司机PLUS
                <v-chip style="position: relative; bottom: 2px"
                >50次解锁/天</v-chip
                ></span
                >
                <span class="title mt-4">▶️ 视频在线播放</span>
                <span class="title mt-4">🖼️ 图包在线浏览</span>
                <span class="title mt-4">🚁 SVIP服务器</span>
                <span class="title mt-4">🌟 解锁高级SVIP资源</span>
                <v-btn
                    width="150px"
                    height="42px"
                    dark
                    class="mt-10 elevation-5 regBtn"
                    to="/order?plan=1&svip=1"
                >升级</v-btn
                >
              </v-card>
            </v-hover>
          </v-col>
          <v-col cols="12" md="3">
            <v-hover v-slot:default="{ hover }">
              <v-card
                  class="
                vip-card
                py-10
                d-flex
                flex-column
                align-center
                premium-plan--highlighted-svip
              "
                  :elevation="hover ? 10 : 2"
              >
                <v-chip
                    label
                    style="
                  position: absolute;
                  top: 20px;
                  left: 20px;
                  color: #3930d8;
                "
                    color="#fff"
                    class="font-weight-bold"
                >🔥 推荐</v-chip
                >
                <v-img
                    lazy-src="https://static.moegoat.com/images/vip/v3.png"
                    src="https://static.moegoat.com/images/vip/v3.png"
                    height="150px"
                    contain
                >
                  <template v-slot:placeholder>
                    <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                      <v-progress-circular
                          indeterminate
                          color="grey lighten-1"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-card-title class="my-3 headline">💎 12个月巴士会SVIP</v-card-title>
                <span class="display-1 mb-2 font-weight-light">
                <del style="font-style: italic">¥300</del>
                <span style="color: #fff50e" class="font-weight-bold ml-3 mr-1"
                >¥198</span
                >
                <v-chip
                    color="#FFF50E"
                    class="font-weight-regular"
                    style="position: relative; top: -3px"
                >⏰ 限时</v-chip
                ></span
                >
                <span class="title mt-4"
                >🏎️ 专业车手PLUS
                <v-chip
                    dark
                    color="blue"
                    style="position: relative; bottom: 3px"
                >80次解锁/天</v-chip
                ></span
                >
                <span class="title mt-4">▶️ 视频在线播放</span>
                <span class="title mt-4">🖼️ 图包在线浏览</span>
                <span class="title mt-4 font-weight-light"
                >🚀 SVIP高速服务器<span class="font-weight-bold"
                >+⚡优化线路</span
                ></span
                >
                <span class="title mt-4 font-weight-bold"
                >💎 解锁高阶稀缺资源</span
                >

                <v-btn
                    width="150px"
                    height="42px"
                    class="mt-10 elevation-5 goPremium-btn"
                    to="/order?plan=2&svip=1"
                >限时升级</v-btn
                >
              </v-card>
            </v-hover>
          </v-col>
          <v-col cols="12" md="3">
            <v-hover v-slot:default="{ hover }">
              <v-card
                  class="
                vip-card
                py-10
                d-flex
                flex-column
                align-center
                premium-plan--lifetime-svip
              "
                  :elevation="hover ? 10 : 2"
              >
                <v-img
                    lazy-src="https://static.moegoat.com/images/vip/v4.png"
                    src="https://static.moegoat.com/images/vip/v4.png"
                    height="150px"
                    contain
                >
                  <template v-slot:placeholder>
                    <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                      <v-progress-circular
                          indeterminate
                          color="grey lighten-1"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-card-title class="my-3 headline">👑 终身尊享巴士会SVIP</v-card-title>
                <span class="display-1 mb-2 font-weight-light">
                <del style="font-style: italic">¥800</del>
                <span style="color: #fff50e" class="font-weight-bold ml-3 mr-1"
                >¥368</span
                >
                <v-chip
                    color="#FFF50E"
                    class="font-weight-regular"
                    style="position: relative; top: -3px"
                >💎 限量发行</v-chip
                ></span
                >
                <span class="title mt-4"
                >🛸 传说级PLUS
                <v-chip
                    dark
                    color="#ffad03"
                    class="font-weight-bold"
                    style="position: relative; bottom: 3px"
                >120次解锁/天</v-chip
                ></span
                >
                <span class="title mt-4">▶️ 视频在线播放</span>
                <span class="title mt-4">🖼️ 图包在线浏览</span>
                <span class="title mt-4 font-weight-light"
                >🚀 SVIP极速服务器<span class="font-weight-bold"
                >+⚡高级优化线路</span
                ></span
                >
                <span class="title mt-4 font-weight-bold"
                >👑 解锁全部资源</span
                >
                <v-btn
                    v-if="vipLevel === 4 && svip !== 1"
                    height="42px"
                    class="mt-10 elevation-5 goPremium-btn"
                    to="/order?plan=3&svip=1"
                    color="#fff"
                >限时补差价升级￥170</v-btn>

                <v-btn
                    v-else
                    width="150px"
                    height="42px"
                    class="mt-10 elevation-5 goPremium-btn"
                    to="/order?plan=3&svip=1"
                    color="#fff"
                >限量升级</v-btn>

              </v-card>
            </v-hover>
          </v-col>
        </v-row></v-tab-item>
      </v-tabs-items>
    </v-container>
  </div>
</template>

<script>
import HeaderBanner from "@/components/HeaderBanner.vue";
import {getQueryVariable} from "@/utils";
import  {getVipLevel} from '@/utils/auth'
import  {getIsSvip} from '@/utils/auth'

export default {
  name: "VipPage",
  components: {
    HeaderBanner,
  },
  data() {
    return {
      tab:0,
      svip:0,
      vipLevel:0
    }
  },
  mounted() {
    // 当组件挂载时，重置 overflowY
    document.documentElement.style.overflowY = "auto";
  },
  created() {
    this.vipLevel = Number(getVipLevel());
    this.svip = Number(getIsSvip());
    this.tab = Number(getQueryVariable("svip")) || 0;
  }
};
</script>

<style scoped>
.premium-plan .goPremium-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  padding: 12px 45px;
  text-align: center;
  border-radius: 5px;
  margin: 0;
  color: #3930d8;
  background: white;
  border: 1px solid #3930d8;
  outline: none;
}

.premium-plan--highlighted {
  color: #fff;
  background-color: #3930d8;
}

.premium-plan--highlighted-svip {
  background: hsla(211, 96%, 62%, 1);

  background: linear-gradient(90deg, hsl(243, 68%, 52%) 0%, hsla(295, 94%, 76%, 1) 100%);

  background: -moz-linear-gradient(90deg, hsl(243, 68%, 52%) 0%, hsla(295, 94%, 76%, 1) 100%);

  background: -webkit-linear-gradient(90deg, hsl(243, 68%, 52%) 0%, hsla(295, 94%, 76%, 1) 100%);

  color: #fff;
}

.premium-plan--lifetime {
  background: linear-gradient(
    to right,
    #b8cbb8 0%,
    #b8cbb8 0%,
    #b465da 0%,
    #cf6cc9 33%,
    #ee609c 66%,
    #ee609c 100%
  );
  color: #fff;
}

.premium-plan--lifetime-svip {
  background: hsla(333, 100%, 53%, 1);

  background: linear-gradient(90deg, hsla(333, 100%, 53%, 1) 0%, hsla(33, 94%, 57%, 1) 100%);

  background: -moz-linear-gradient(90deg, hsla(333, 100%, 53%, 1) 0%, hsla(33, 94%, 57%, 1) 100%);

  background: -webkit-linear-gradient(90deg, hsla(333, 100%, 53%, 1) 0%, hsla(33, 94%, 57%, 1) 100%);

  color: #fff;
}

.premium-plan--highlighted .goPremium-btn {
  background-color: #fff50e;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  line-height: 21px;
  padding: 12px 45px;
}
</style>