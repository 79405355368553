<template>
  <v-img :src="bannerImg" :lazy-src="bannerImg" :height="dynamicHeight" width="100%">
    <v-row
      align="center"
      justify="center"
      class="fill-height"
      :style="{ transform: `translateY(${top})` }"
    >
      <v-col>
        <h1
          class="text-center white--text"
          :class="{ headline: $vuetify.breakpoint.xsOnly }"
          v-text="title"
        ></h1>
        <p
          class="banner-sub-title"
          v-if="subTitle"
          v-text="subTitle"
          :class="{ 'px-7': $vuetify.breakpoint.mobile }"
        ></p>
      </v-col>
    </v-row>
  </v-img>
</template>
<script>
export default {
  name: "HeaderBanner",
  props: {
    bannerImg: {
      type: String,
    },
    title: {
      type: String,
    },
    subTitle: {
      type: String,
    },
    top: {
      default: "28px",
      required: false,
    },
  },
  computed: {
    dynamicHeight() {
      return this.$vuetify.breakpoint.xsOnly ? '168px' : '250px';
    }
  }
};
</script>
<style scope>
.banner-sub-title {
  color: #999;
  font-size: 18px;
  text-align: center;
  padding-top: 15px;
}
</style>

